import { useCached3DObjectIfReady } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import {
  FloorPlanRendererBase,
  LodPointCloudRendererBase,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { includes } from "@faro-lotv/foundation";
import {
  IElement,
  IElementImg360,
  IElementImgSheet,
  IElementImgSheetTiled,
  IElementPointCloudStream,
  IElementType,
  isIElementImg360,
  isIElementImgSheet,
  isIElementImgSheetTiled,
  isIElementPointCloudStream,
} from "@faro-lotv/ielement-types";
import { LodFloorPlan, TextureLoader } from "@faro-lotv/lotv";
import { Sphere } from "@react-three/drei";
import { useEffect, useState } from "react";
import { DoubleSide, Texture } from "three";

/**
 * @returns true if the element type can be rendered in the 3d scene
 * @param type of the element to check
 */
export function canBeRendered(type: IElementType | string): boolean {
  return includes(
    [
      IElementType.img360,
      IElementType.pointCloudStream,
      IElementType.imgSheet,
      IElementType.imgSheetTiled,
    ],
    type,
  );
}

export type PropsWithElement<ElementType extends IElement = IElement> = {
  element: ElementType;
};

/** @returns the 3d data for an element*/
export function ElementDataRenderer({
  element,
}: PropsWithElement): JSX.Element | null {
  if (isIElementPointCloudStream(element)) {
    return <PCRenderer element={element} />;
  }
  if (isIElementImg360(element)) {
    return <Img360Renderer element={element} />;
  }
  if (isIElementImgSheet(element)) {
    return <FloorPlanRenderer element={element} />;
  }
  if (isIElementImgSheetTiled(element)) {
    return <TiledFloorPlanRenderer element={element} />;
  }

  return null;
}

function PCRenderer({
  element,
}: PropsWithElement<IElementPointCloudStream>): JSX.Element | null {
  const pc = useCached3DObjectIfReady(element);
  const pose = useAppSelector(selectIElementWorldTransform(element.id));

  if (!pc || pc instanceof Error) return null;

  return (
    <group {...pose} name={element.id}>
      <LodPointCloudRendererBase pointCloud={pc} />
    </group>
  );
}

const TEXTURE_LOADER = new TextureLoader();

function Img360Renderer({
  element,
}: PropsWithElement<IElementImg360>): JSX.Element | null {
  const pose = useAppSelector(selectIElementWorldTransform(element.id));
  const [texture, setTexture] = useState<Texture>();

  useEffect(() => {
    const { abort, promise } = TEXTURE_LOADER.load(
      element.thumbnailUri ?? element.uri,
    );
    promise.then(setTexture);

    return () => abort.abort();
  }, [element]);

  if (!texture) return null;

  return (
    <group {...pose} name={element.id}>
      <Sphere
        args={[1]}
        material-map={texture}
        material-side={DoubleSide}
        // Align the R3F sphere geometry to work like pano projections work in the Sphere Viewer
        scale-x={-1}
        rotation-y={Math.PI}
      />
    </group>
  );
}

function FloorPlanRenderer({
  element,
}: PropsWithElement<IElementImgSheet>): JSX.Element | null {
  const mesh = useCached3DObjectIfReady(element);

  const DEFAULT_MAP_SIZE = 100;
  const mapWidth = element.size?.x ?? DEFAULT_MAP_SIZE;
  const mapHeight = element.size?.z ?? DEFAULT_MAP_SIZE;

  const globalPosition = useAppSelector(
    selectIElementWorldTransform(element.id),
  );

  if (!mesh || mesh instanceof Error) return null;

  return (
    <group {...globalPosition}>
      {/* The mesh seems to be centered on the Upper Left corner this centers it around the scene */}
      <primitive
        object={mesh}
        position={[mapWidth / 2, 0, -mapHeight / 2]}
        name={element.id}
      />
    </group>
  );
}

function TiledFloorPlanRenderer({
  element,
}: PropsWithElement<IElementImgSheetTiled>): JSX.Element | null {
  const floorPlan = useCached3DObjectIfReady(element);

  const globalPosition = useAppSelector(
    selectIElementWorldTransform(element.id),
  );

  if (
    !floorPlan ||
    floorPlan instanceof Error ||
    !(floorPlan instanceof LodFloorPlan)
  ) {
    return null;
  }

  return (
    <group {...globalPosition}>
      <FloorPlanRendererBase floorPlan={floorPlan} minPixelSize={100} />
    </group>
  );
}
