import { CLIENT_ID, useEnvConfig } from "@/env-config";
import { GUID } from "@faro-lotv/foundation";
import { useCoreApiTokenProvider } from "@faro-lotv/service-wires";
import { PropsWithChildren, useEffect } from "react";
import { AuthContext, useAuthContext } from "./auth-context";

type LoginProps = {
  /** Environment to login to */
  env?: string;

  /** Region to login to */
  region?: string;
};

/**
 * @returns a wrapper that ensures that the current user is logged in to the specified environment and region.
 */
export function HBEnsureLogin({
  env,
  region,
  children,
}: PropsWithChildren<LoginProps>): JSX.Element | null {
  const { isLoggedIn, requestLogin } = useAuthContext();

  useEffect(() => {
    requestLogin(env, region);
  }, [env, region, requestLogin]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

type ProjectAccessProps = {
  projectId: GUID;
};

/** @returns a wrapper that augment the auth context with this project context provider */
export function HBProjectAccess({
  projectId,
  children,
}: PropsWithChildren<ProjectAccessProps>): JSX.Element {
  const env = useEnvConfig();
  const origContext = useAuthContext();

  const tokenProvider = useCoreApiTokenProvider({
    coreApiBaseUrl: env.coreApiUrl,
    projectId,
    clientId: CLIENT_ID,
  });

  return (
    <AuthContext.Provider value={{ ...origContext, tokenProvider }}>
      {children}
    </AuthContext.Provider>
  );
}
