import { appTheme } from "@/app-theme";
import { ToastProvider } from "@faro-lotv/flat-ui";
import { initLotvWorkers } from "@faro-lotv/lotv";
import GltfLoading from "@faro-lotv/web-workers/dist/GltfLoading.worker.js?url";
import PointCloudLoading from "@faro-lotv/web-workers/dist/PointCloudLoading.worker.js?url";
import PotreeNodes from "@faro-lotv/web-workers/dist/PotreeNodes.worker.js?url";
import WSNodes from "@faro-lotv/web-workers/dist/WSNodes.worker.js?url";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HBAuthProvider } from "./components/auth/hb-auth";
import { Home } from "./pages/home";
import { Project } from "./pages/project";
import { Projects } from "./pages/projects";
import { store } from "./store/store";

/**
 * @returns a wrapper to apply all the global context providers needed for the app
 */
export function AllTheProviders({ children }: PropsWithChildren): JSX.Element {
  return (
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <ToastProvider>
          <HBAuthProvider>{children}</HBAuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </Provider>
  );
}

/**
 * @returns main application entry point
 */
export function App(): JSX.Element {
  /**
   * Setting the workers location to make them available to LotV during run time
   * Configuration to make them available in public, is under config-overrides.js
   */
  initLotvWorkers({
    GltfLoading,
    PointCloudLoading,
    PotreeNodes,
    WSNodes,
  });

  return (
    <AllTheProviders>
      <Box component="div" sx={{ width: "100%", height: "100%" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/:env/:region/" element={<Projects />} />
            <Route path="/project/:projectId" element={<Project />} />
            <Route path="/:env/:region/:projectId" element={<Project />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </AllTheProviders>
  );
}
