import {
  selectActiveArea,
  selectIsDataSetFromActiveArea,
} from "@/store/areas-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FaroMenu, FaroMenuItem, ThreeDotsIcon } from "@faro-lotv/flat-ui";
import { IElement } from "@faro-lotv/ielement-types";
import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { useEvents } from "../events";
import { TreeNodeProps } from "./tree";

export type ContextMenuProps = Pick<TreeNodeProps, "node"> & {
  element: IElement;
};

/** @returns the context menu for a menu item */
export function ContextMenu({ element, node }: ContextMenuProps): JSX.Element {
  const activeAreaId = useAppSelector(selectActiveArea);
  const isFromActiveArea = useAppSelector(
    selectIsDataSetFromActiveArea(node.data.id),
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { lookAt } = useEvents();
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        ref={ref}
        onClick={(ev) => {
          setIsMenuOpen(true);
          ev.stopPropagation();
        }}
        sx={{ width: "1rem", height: "1rem", m: 0, p: 0 }}
      >
        <ThreeDotsIcon
          aria-label="expand sub-menu"
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      </Button>
      <FaroMenu
        open={isMenuOpen}
        onClose={(ev) => {
          setIsMenuOpen(false);
          if (
            "stopPropagation" in ev &&
            typeof ev.stopPropagation === "function"
          ) {
            ev.stopPropagation();
          }
        }}
        anchorEl={ref.current}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <FaroMenuItem
          label="Copy Id"
          onClick={(ev) => {
            navigator.clipboard.writeText(element.id);
            setIsMenuOpen(false);
            ev.stopPropagation();
          }}
        />
        <FaroMenuItem
          label="Look At"
          onClick={(ev) => {
            lookAt.emit(node.data.id);
            setIsMenuOpen(false);
            ev.stopPropagation();
          }}
        />
        {isFromActiveArea && activeAreaId && (
          <FaroMenuItem
            label="Select Area"
            onClick={(ev) => {
              node.tree.select(activeAreaId);
              setIsMenuOpen(false);
              ev.stopPropagation();
            }}
          />
        )}
      </FaroMenu>
    </>
  );
}
