import { CLIENT_ID, useEnvConfig } from "@/env-config";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { FaroText, LoadingScreen } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  addIElements,
  disableTreeFiltering,
  initializeProject,
  selectProjectName,
  setProjectName,
  setRootId,
} from "@faro-lotv/project-source";
import {
  useCoreApiClient,
  useProjectApiClient,
} from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectView } from "../project-view";
import { AreasPanel } from "./areas-panel";
import { SelectedElementPanel } from "./current-element-panel";
import { PanelContainer } from "./panel-container";
import { ProjectPanel } from "./project-panel";
import { ProjectTabs, UiTabs } from "./project-tabs";

type ProjectUiProps = {
  projectId: GUID;
};

/**
 * @returns the main ui of the project page
 */
export function ProjectUi({ projectId }: ProjectUiProps): JSX.Element {
  const config = useEnvConfig();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const projectName = useAppSelector(selectProjectName);

  const projectApi = useProjectApiClient(
    config.projectApiUrl,
    config.coreApiUrl,
    projectId,
    CLIENT_ID,
  );

  const coreApi = useCoreApiClient(config.coreApiUrl, CLIENT_ID);

  // Load the current project
  useEffect(() => {
    const ac = new AbortController();
    setIsLoading(true);
    dispatch(initializeProject(projectId));
    dispatch(disableTreeFiltering());
    const pApiPromise = projectApi
      .getAllIElements({ signal: ac.signal })
      .then((elements) => {
        dispatch(setRootId(elements[0].rootId));
        dispatch(addIElements(elements));
      });
    const cApiPromise = coreApi.getProjectInfo(projectId).then((info) => {
      dispatch(setProjectName(info.name));
    });
    Promise.allSettled([pApiPromise, cApiPromise]).then(() => {
      setIsLoading(false);
    });
    return () => ac.abort();
  }, [coreApi, dispatch, projectApi, projectId]);

  const [currentTab, setCurrentTab] = useState<UiTabs>(UiTabs.project);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Stack
      direction="row"
      justifyContent="stretch"
      sx={{ width: "100%", height: "100%" }}
    >
      <Stack direction="column" component="span" sx={{ width: "50%", m: 1 }}>
        <FaroText variant="heading20">{projectName}</FaroText>
        <ProjectTabs current={currentTab} onCurrentChanged={setCurrentTab} />
        <PanelContainer visible={currentTab === UiTabs.project}>
          <ProjectPanel />
        </PanelContainer>
        <PanelContainer visible={currentTab === UiTabs.currentElement}>
          <SelectedElementPanel />
        </PanelContainer>
        <PanelContainer visible={currentTab === UiTabs.areas}>
          <AreasPanel projectId={projectId} />
        </PanelContainer>
      </Stack>
      <Box component="span" sx={{ width: "50%" }}>
        <ProjectView />
      </Box>
    </Stack>
  );
}
