import { CLIENT_ID, useEnvConfig } from "@/env-config";
import { setActiveArea } from "@/store/areas-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import {
  IElementSection,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import {
  selectChildrenDepthFirst,
  selectRootIElement,
} from "@faro-lotv/project-source";
import { useProjectApiClient } from "@faro-lotv/service-wires";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { AreaDataSets } from "./area-datasets-view";

export type UseLoadAreaDataSetsReturn = {
  areaDataSets: AreaDataSets | Error | undefined;
  setAreaDataSets(areaDataSets: AreaDataSets): void;
  reload(): void;
};

/**
 * @param projectId id of the project to load the area datasets for
 * @returns the loaded AreaTree or undefined while loading, and a few functions to edit or reload the mapping
 */
export function useLoadAreaDataSets(
  projectId: GUID,
): UseLoadAreaDataSetsReturn {
  const projectAreas = useAppSelector(selectAreas, isEqual);
  const config = useEnvConfig();
  const dispatch = useAppDispatch();

  const projectApi = useProjectApiClient(
    config.projectApiUrl,
    config.coreApiUrl,
    projectId,
    CLIENT_ID,
  );

  const [areaDataSets, setAreaDataSets] = useState<AreaDataSets | Error>();

  const reload = useCallback(() => {
    const ac = new AbortController();

    const load = async (signal: AbortSignal): Promise<AreaDataSets> => {
      const newTree: AreaDataSets = {};

      for (const area of projectAreas) {
        const toAdd = await projectApi.queryAreaVolume(area.id, signal);

        Object.assign(newTree, { [area.id]: toAdd });
      }

      return newTree;
    };

    dispatch(setActiveArea(undefined));
    setAreaDataSets(undefined);
    load(ac.signal)
      .then(setAreaDataSets)
      .catch((reason) => {
        setAreaDataSets(
          reason instanceof Error ? reason : new Error(JSON.stringify(reason)),
        );
      });

    return () => ac.abort();
  }, [dispatch, projectApi, projectAreas]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    areaDataSets,
    setAreaDataSets,
    reload,
  };
}

/**
 * @param state app state
 * @returns all the area sections
 */
function selectAreas(state: RootState): IElementSection[] {
  const root = selectRootIElement(state);
  return selectChildrenDepthFirst(root, isIElementAreaSection, 2)(state);
}
